import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PLATFORM_BASE_URL } from "../config/common"
import { COMPANY_DETAILS } from "../config"
import { Button } from "./Button/Button"

export const GetStartedButton = ({ text = 'Get started' }) => {
  return (
    <Button
      isLink
      isExternalLink
      link={PLATFORM_BASE_URL}
    >
      {<>{text} &#8594;</>}
    </Button>
  )
}