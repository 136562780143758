import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/App/Layout"

export const CommonHeaderAndFooter = ({ children }) => {
  return (
    <Layout>
      {children}
    </Layout>
  )
}

CommonHeaderAndFooter.propTypes = {
  children: PropTypes.node.isRequired
}