import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import BaseTooltip from "react-bootstrap/Tooltip"
import { FONT_AWESOME_ICONS } from "../config/icons"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

export const WithTooltip = ({ children, tooltipContent, tooltipId, placement = 'top' }) => {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 0, hide: 0 }}
      overlay={(props) => {
        return (
          <BaseTooltip id={tooltipId} {...props}>
            {tooltipContent}
          </BaseTooltip>
        )
      }}
    >
      {children}
    </OverlayTrigger>
  )
}

WithTooltip.propTypes = {
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltipContent: PropTypes.node.isRequired,
  tooltipId: PropTypes.string.isRequired,
}