import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"

import Shape2 from '../../assets/images/shape/shape2.png'
import Shape3 from '../../assets/images/shape/shape3.png'
import Shape5 from '../../assets/images/shape/shape5.png'
import Shape6 from '../../assets/images/shape/shape6.png'
import Shape7 from '../../assets/images/shape/shape7.png'
import Shape13 from '../../assets/images/shape/shape13.png'
import GetStartedImg from '../../assets/images/get_started_with_us_graphic.png'

import { GetStartedButton } from "../GetStartedButton"

import { COMPANY_NAME } from "../../config/common"

export const GetStartedWithUs = ({ mentionCompanyName = true, showShapes = false }) => {
  return (
    <div className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img src={GetStartedImg} alt="Get started with us" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <span className="sub-title">Jump in!</span>
              <h2>Get started with {mentionCompanyName ? COMPANY_NAME : 'us'} today!</h2>
              <p>
                Take your customer service to the next level by
                using {mentionCompanyName ? COMPANY_NAME + "'s" : 'our'} technology to provide amazing CX.
              </p>

              <GetStartedButton />
            </div>
          </div>
        </div>
      </div>

      {showShapes && (
        <>
          <div className="shape2"><img src={Shape2} alt="Shape" /></div>
          <div className="shape3"><img src={Shape3} alt="Shape" /></div>
          <div className="shape5"><img src={Shape5} alt="Shape" /></div>
          <div className="shape6"><img src={Shape6} alt="Shape" /></div>
          <div className="shape7"><img src={Shape7} alt="Shape" /></div>
          <div className="shape13"><img src={Shape13} alt="Shape" /></div>
        </>
      )}
    </div>
  )
}

GetStartedWithUs.propTypes = {
  showShapes: PropTypes.bool,
  mentionCompanyName: PropTypes.bool,
}